import axios from 'axios'
import { domain, useJSONResponse } from '@/api/instances/common'

const instance = axios.create({
  baseURL: domain({ subdomain: 'auth' }),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

instance.interceptors.response.use(useJSONResponse)

export default instance
