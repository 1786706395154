
import { useQuery, useMutation } from 'vue-query'
import { signIn, signUp } from '@/api/auth'

export const useAuthQuery = {
  // signIn({ email, password, options = {} }) {
  //   return useQuery(
  //     ['sign_in', { email }],
  //     () => signIn({ email, password }),
  //     options
  //   )
  // },

  signIn() {
    return useMutation(signIn)
  },

  signUp() {
    return useMutation(signUp)
  },
}
